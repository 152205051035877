@import 'variables.scss';

body {
    background: black;
    color: white;
    font-family: $primary-font;
    margin: 0;
}

blockquote {
    background: $grey-light;
    border-left: .5rem solid $grey-dark;
    padding: .5rem 1rem;
}

@media (max-width: 576px) {
    blockquote {
        margin: 16px;
    }
}

header,
footer,
.container {
    padding: 0;
}

header {
    margin-bottom: 16px;
}

footer {
    text-align: center;
    margin-top: 64px;
    margin-bottom: 16px;
}

.breadcrumb {
    align-items: center;
    background-color: $primary-color-dark;
    display: flex;
    line-height: 2;
    padding-left: 16px;
    padding-right: 16px;
}

.breadcrumb a {
    background-color: $primary-color-dark;
    color: #fff;
    margin-right: 16px;
}

a {
    color: $primary-color-dark;
    text-decoration: none;
    opacity: 1;
}

a:focus,
a:hover {
    transition: opacity .15s ease-in;
}

a:focus,
a:hover {
    opacity: .5;
}

a:active {
    opacity: .8;
    transition:opacity .15s ease-out;
}

ul {
    list-style-type: none;
}

#home {
    line-height: 1.5;
}

@media (max-width: 576px) {
    #home h2 {
        text-align: center;
    }
}

.avatar > img {
    border-radius: 50%;
}

@media (max-width: 576px) {
    .avatar {
        width: 50%;
    }
}

#content {
    & > h2, p {
        text-align: center;
    }

    & > h2 {
        font-size: 1em;
    }
}

.social-media-links {
    margin-top: 24px;
    text-align: center;
}

.social-media {
    color: white;
    font-size: 2rem;
    margin-right: 16px;
}

.social-media img {
    width: 32px;
}

#recent-posts {
    margin: 32px auto;
    width: 80%;
}

@media (max-width: 576px) {
    #recent-posts {
        width: 88%;
    }
}

#recent-posts > h2 {
    font-size: 2rem;
}

#recent-posts > ul,
#list > ul {
    padding: 0;
}

#recent-posts > ul > li {
    margin-bottom: 4px;
}

@media (max-width: 576px) {
    #recent-posts > ul > li {
        margin-bottom: 8px;
    }
}

#recent-posts > ul > li > span,
#list > ul > li > p {
    color: $grey;
    font-family: Consolas, monaco, monospace;
    margin-right: 8px;
    font-weight: normal;
}

#outtro-content {
    margin-top: 5%;
    h4 {
        font-size: 1.75rem;
    }
}

.footer-text {
    font-size: 1.15rem;
}

#list {
    margin-left: auto;
    margin-right: auto;
    max-width: 64%;
}

@media (max-width: 576px) {
    #list {
        max-width: 88%;
    }
}

#list > h1 {
    font-size: 2.5rem;
    margin: .67em 0;
}

#list > ul > li {
    margin-bottom: 32px;
    font-weight: bold;
}

#single {
    & > h1 {
        font-size: 2rem;
        margin: .67em 0;
    }
    & > h2 {
        font-size: 1.5rem;
        margin: .83em 0;
    }
    & > h3 {
        font-size: 1.5rem;
    }
    line-height: 1.5;
    text-align: center;
    padding: 8px 24px;
}

.post-content {
    line-height: 2;
    margin: 8px 48px;
    text-align: justify;
}

@media (max-width: 576px) {
    .post-content {
        margin: 8px;
    }
}

.post-content {
    & > p {
        margin: 2rem 0;
    }

    & > p code {
        background-color: $grey-light;
        padding: 2px 4px;
        border-radius: 2px;
    }
}

.post-content ul {
    list-style-type: disc;
}

/* Override syntax highlighting styles */

.hljs {
    border-radius: .25rem;
    padding: 1rem;
}
