// @import 'reset.scss';
@import 'solarized-light.scss';
@import 'hugo-bingo.scss';
@import 'nav.scss';

.video-header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;

    & > .video-header-content {
        -webkit-box-shadow: inset 0 0 5vw 10vw rgba(0,0,0,1);
        -moz-box-shadow: inset 0 0 5vw 10vw rgba(0,0,0,1);
        box-shadow: inset 0 0 5vw 10vw rgba(0,0,0,1);

        display: flex;
        position: absolute;
        top: 0;
        color: white;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;

        & > .avatar {
            margin: 25px auto;
            width: 100%;

            & > img {
                width: 15%;
            }
        }
    }
}

.bg-video {
    max-width: 100%; 
    max-height: 100%;
}

.technology {
    $Xtrans: random($limit: 5) + px;
    $Ytrans: random($limit: 10) + px;

    animation: swim 5s forwards;
    animation-iteration-count: infinite;
    position: absolute;

    @keyframes swim {
        0% {
            transform:translateX(0);
            transform:translateY(0);
        }
        50% {
            transform:translate($Xtrans, $Ytrans);
        }
        100%{
            transform:translateX(0);
            transform:translateY(0);
        }
    }
}

.diver-logo {
    animation: floating 10s forwards;
    animation-iteration-count: infinite;
    position: absolute;
    width: 15%;
    top: 60%;
    left: 5%;

    @keyframes floating {
        0% {
            transform:translateY(0);
        }
        50% {
            transform:translateY(-10%);
        }
        100%{
            transform:translateY(0);
        }
    }
}

.cta {
    margin-top: 3rem;
    font-size:  smaller;
}

@media (max-width: 576px) {
    .technology {
        width: 1rem;
    }
}